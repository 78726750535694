<template>
  <Default>
    <div class="tw-flex tw-justify-between tw-items-center tw-py-2 md:tw-py-4 tw-bg-white">
      <v-chip-group mandatory active-class="primary">
        <v-chip ref="dashboard-chip" :to="{ name: 'Dashboard' }" class="tw-text-white tw-bg-blue-600 tw-shadow-md tw-px-3 tw-mx-5">
          Dashboard
        </v-chip>
      </v-chip-group>
      <v-icon
        class="tw-text-blue-600 tw-cursor-pointer hover:tw-text-blue-800 tw-bg-gray-200 tw-rounded-full tw-p-1 tw-w-6 tw-h-6 tw-flex tw-justify-center tw-items-center refresh"
        @click="refreshData">
        mdi-reload
      </v-icon>
    </div>
    <v-card elevation="2" class="tw-px-4 md:tw-px-8 tw-pt-6 tw-pb-8 tw-rounded-lg tw-shadow-lg tw-mt-4">
      <card-title icon="/images/icons/top-icon.png">Dashboard Overview</card-title>
      <div v-if="!loading"
        class="tw-w-full tw-px-2 md:tw-px-4 tw-py-4 md:tw-py-10 tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-4 md:tw-gap-6">
        <v-card v-for="(i, index) in items" :key="index" elevation="2"
          class="tw-flex tw-flex-col tw-justify-between tw-px-4 md:tw-px-6 tw-py-8 tw-rounded-lg tw-transition tw-duration-300 hover:tw-shadow-xl tw-border tw-border-gray-200 tw-bg-blue-50 tw-cursor-pointer tw-h-full">
          <card-title icon="/images/icons/list-star.png" />
          <div class="tw-flex tw-flex-col tw-items-center tw-text-center tw-mb-4">
            <p class="tw-text-gray-700 tw-text-md tw-font-semibold">{{ i.agentStatName }}</p>
            <p class="tw-text-blue-800 tw-font-bold tw-text-2xl">{{ i.agentStatValue }}</p>
          </div>
          <hr class="tw-mt-2 md:tw-mt-4">
        </v-card>
      </div>
      <div v-else class="tw-w-full tw-flex tw-justify-center tw-items-center tw-h-40 loader">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-card>
    <saved-quotes />
  </Default>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import Default from '../layouts/Default.vue';
import CardTitle from '../components/CardTitle';
import SavedQuotes from '../components/dashboard/SavedQuotes';

export default {
  name: 'Dashboard',
  components: { SavedQuotes, CardTitle, Default },
  metaInfo: {
    title: 'Dashboard',
  },
  data() {
    return {
      items: [],
      loading: true,
      currentTab: SavedQuotes,
    };
  },
  computed: {
    ...mapState({
      agentCode: (state) => state.auth.userInfo.agnCode,
    }),
  },
  // mounted() {
  //   const formData = this.agentCode;
  //   axios.get(`/lmsagent/quotations/findAgentQuotesStats?agent Code=${formData}`)
  //     .then((res) => {
  //       this.items = res.data.data;
  //       console.log('response', res.data.data);
  //       this.loading = false;
  //     });
  // },

  methods: {
    // refresh data
    refreshData() {
      this.loading = true;
      this.fetchData();
    },
    fetchData() {
      const formData = this.agentCode;
      axios.get(`/lmsagent/quotations/findAgentQuotesStats?agent Code=${formData}`)
        .then((res) => {
          this.items = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
