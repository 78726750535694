<template>
  <v-card elevation="2" class="tw-px-3 md:tw-px-8 tw-mt-4 md:tw-mt-10 tw-rounded-lg tw-shadow-lg">
    <card-title icon="/images/icons/top-icon.png"
      class="tw-text-blue-800 tw-text-lg md:tw-text-xl tw-font-bold tw-my-4">
      Saved Quotations
    </card-title>

    <!-- Search Bar Alignment -->
    <div class="tw-flex tw-justify-end tw-mb-4">
      <div class="tw-w-full sm:tw-w-1/3 md:tw-w-1/4">
        <v-text-field v-model="search" @input="filter" append-icon="mdi-magnify" label="Search" single-line hide-details
          class="tw-transition-all tw-duration-300 tw-transform tw-bg-blue-50 tw-border tw-border-gray-200 tw-rounded-md tw-py-2 tw-px-4 hover:tw-border-blue-500 hover:tw-bg-blue-100 focus:tw-scale-105 focus:tw-border-blue-500 focus:tw-bg-blue-100"></v-text-field>
      </div>
    </div>

    <hr />

    <!-- Data Table with Styled Headers and Content -->
    <v-data-table :headers="headers" :items="search ? filteredData : quotes" :search="search"
      sort-by="quoteEffectiveDate" :sort-desc.sync="sortDesc" :page.sync="pagination.pageNumber"
      :items-per-page.sync="pagination.perPage" :server-items-length="pagination.totalItems" :loading="loadingTable"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 30, 40, 50],
      }" class="tw-rounded-lg tw-shadow-md tw-bg-white">
      <!-- Icon for Viewing Quote -->
      <template v-slot:[`item.quoteCode`]="{ item }">
        <v-btn text :key="item.quoteCode" @click="viewMore(item)" class="tw-px-3 tw-py-1 tw-rounded-full">
          <v-icon color="blue-600" class="tw-text-lg tw-mr-1">mdi-eye</v-icon>
          <span class="tw-text-blue-600 tw-font-medium">View</span>
        </v-btn>
      </template>

      <!-- Resume Quote Button with Styling -->
      <template v-slot:[`item.savePoint`]="{ item }">
        <v-btn :disabled="!item.savePoint" depressed color="warning" :key="item.savePoint" @click="resumeQuote(item)"
          class="tw-px-4 tw-py-1 tw-rounded-full tw-font-semibold tw-text-white">
          Resume
        </v-btn>
      </template>

      <!-- Sum Assured with Consistent Font -->
      <template v-slot:[`item.sumAssured`]="{ item }">
        <span class="tw-text-gray-700 tw-font-semibold">{{ item.sumAssured | formatNum }}</span>
      </template>

      <!-- Premium with Consistent Font -->
      <template v-slot:[`item.premium`]="{ item }">
        <span class="tw-text-gray-700 tw-font-semibold">{{ item.premium | formatNum }}</span>
      </template>

      <!-- Quote Effective Date with Formatted Date -->
      <template v-slot:[`item.quoteEffectiveDate`]="{ item }">
        <span class="tw-text-gray-600 tw-text-sm">{{ item.quoteEffectiveDate | formatToHuman }}</span>
      </template>
    </v-data-table>

    <!-- Saved Quote Dialog -->
    <saved-quote-dialog :quoteSummaryData="quoteSummaryData" :dialogView="dialogView" @changeDialog="updateDialog" />
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import CardTitle from '../CardTitle';
import SavedQuoteDialog from '../dialogs/SavedQuoteSummary';
import { formatToHuman } from '@/utils/time';
import savedQuoteMixin from '@/mixins/savedQuoteMixin';
import helpers from '../../utils/helpers';
import ProductMixin from '@/mixins/ProductMixin';

export default {
  name: 'SavedQuotes',
  components: { CardTitle, SavedQuoteDialog },
  mixins: [savedQuoteMixin, ProductMixin],
  data() {
    return {
      loadingTable: true,
      search: '',
      headers: [
        { text: 'Customer', align: 'start', value: 'client.clientName' },
        {
          text: 'Cover Type',
          value: 'benefits[0].quoteBenefitName',
        },
        { text: 'Sum Assured', value: 'sumAssured' },
        { text: 'premium', value: 'premium' },
        { text: 'Action', value: 'quoteCode' },
        { text: 'Resume', value: 'savePoint' },
      ],
      quotes: [],
      filteredData: [],
      isDescending: true,
      agentCode: null,
      quoteSummaryData: {},
      dialogView: false,
      fetchSavedQuoteUrl: '/lmsagent/quotations/findAgentQuotes',
    };
  },
  computed: {
    ...mapState({
      agnCode: (state) => state.auth.userInfo.agnCode,
    }),
  },
  mounted() {
    this.agentAssign();
    this.fetchSavedQuote(this.agentCode);
    this.sortBy = [false, false, false, false];
  },
  methods: {
    filter() {
      const searchTerm = this.search;
      const result = this.quotes.filter((item) => item.client.clientName.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
      this.filteredData = result;
    },
    viewMore(item) {
      this.quoteSummaryData = { ...item };
      this.dialogView = true;
    },
    updateDialog() {
      this.dialogView = false;
    },
    agentAssign() {
      this.agentCode = this.agnCode;
    },
    // resumeQuote(item) {
    //   this.$store.commit('quote/setSaved', item);
    //   if (item.savePoint === 'C' && item.prodCode === 2013103) {
    //     this.$router.push('/products/rfq/elimu-plan');
    //   } else if (item.savePoint === 'C' && item.prodCode === 2020196) {
    //     this.$router.push('/cover/agent-guide');
    //   } else if (item.savePoint === 'B') {
    //     this.$router.push('/cover/buy-cover');
    //     this.changetoPaymentOptions();
    //   } else if (item.savePoint === 'F') {
    //     this.$router.push('/cover/buy-cover');
    //     this.changetoProposalSummary();
    //   } else if (item.savePoint === 'C' && item.prodCode === 2016194) {
    //     this.$router.push('/products/rfq/term-assurance-plan');
    //   } else if (item.savePoint === 'C' && item.prodCode === 2013102) {
    //     this.$router.push('/products/rfq/akiba-halisi-plan');
    //   } else if (item.savePoint === 'C' && item.prodCode === 2013104) {
    //     this.$router.push('/products/rfq/Imarika');
    //   } else if (item.savePoint === 'C' && item.prodCode === 2021197) {
    //     this.$router.push('/products/rfq/keyman-plan');
    //   } else if (item.savePoint === 'C' && item.prodCode === 2021200) {
    //     this.$router.push('/products/rfq/whole-life-plan');
    //   } else {
    //     this.$router.push('/dashboard');
    //   }
    // },
    resumeQuote(item) {
      this.$store.commit('quote/setSaved', item);
      switch (item.savePoint) {
        case 'C':
          if (item.prodCode === 2013103) {
            this.$router.push('/products/rfq/elimu-plan');
          } else if (item.prodCode === 2020196) {
            this.$router.push('/cover/agent-guide');
          } else if (item.prodCode === 2016194) {
            this.$router.push('/products/rfq/term-assurance-plan');
          } else if (item.prodCode === 2013102) {
            this.$router.push('/products/rfq/akiba-halisi-plan');
          } else if (item.prodCode === 2013104) {
            this.$router.push('/products/rfq/Imarika');
          } else if (item.prodCode === 2021197) {
            this.$router.push('/products/rfq/keyman-plan');
          } else if (item.prodCode === 2021200) {
            this.$router.push('/products/rfq/whole-life-plan');
          } else {
            this.$router.push('/dashboard');
          }
          break;
        case 'B':
          this.$router.push('/cover/buy-cover');
          this.changetoPaymentOptions();
          break;
        case 'F':
          this.$router.push('/cover/buy-cover');
          this.changetoProposalSummary();
          break;
        default:
          this.$router.push('/dashboard');
          break;
      }
    },
  },
  filters: {
    formatToHuman,
    formatNum(num) {
      return helpers.numberToString(num);
    },
  },
};
</script>
