<template>
        <v-dialog
            v-model="dialogView"
            max-width="800px"
            transition="dialog-bottom-transition"
            >
            <v-card>
                <v-toolbar
                    dark
                    color="primary"
                    >
                    <v-btn
                        icon
                        dark
                       @click="exitDialog"
                    >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar>
                <v-container class="mt-6 mx-auto">

                <div class="tw-w-full tw-px-2 md:tw-px-8">
                        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-2 md:tw-gap-3">
                            <div>
                            <p class="c-blue-text">Full Name</p>
                            <p class="c-gray-text tw--mt-3">{{ scanObject(quoteSummaryData, 'client.clientName') }}</p>
                            </div>
                            <div>
                            <p class="c-blue-text">Email Address</p>
                            <p class="c-gray-text tw--mt-3">{{ scanObject(quoteSummaryData, 'client.clientEmail') }}</p>
                            </div>
                            <div>
                            <p class="c-blue-text">Date of Birth</p>
                            <p class="c-gray-text tw--mt-3">{{ scanObject(quoteSummaryData, 'client.clientDateOfBirth') | formatToHuman }}</p>
                            </div>
                            <div>
                            <p class="c-blue-text">Term in Years</p>
                            <p class="c-gray-text tw--mt-3">{{ quoteSummaryData.term }}</p>
                            </div>
                            <div>
                            <p class="c-blue-text">Frequency</p>
                            <p class="c-gray-text tw--mt-3">{{ quoteSummaryData.freqOfPayment | frequencyTitles }}</p>
                            </div>
                            <div>
                            <p class="c-blue-text">Product</p>
                            <p class="c-gray-text tw--mt-3">{{ scanObject(quoteSummaryData, 'benefits.0.quoteBenefitName') }}</p>
                            </div>
                        </div>
                        <div class="tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-my-3 md:tw-my-8 tw-text-center">
                        <div class="tw-w-full md:tw-w-3/3 tw-mt-3 md:tw-mt-8">
                            <div class="tw-flex tw-flex-wrap tw-items-center tw-my-3 md:tw-my-8 tw-text-center">
                            <div class="tw-w-full md:tw-w-1/2 tw-border-r-0 md:tw-border-r-2">
                                <h3 class="c-blue-text tw-font-bold tw-text-sm">Sum Assured</h3>
                                <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">KES {{ Math.round(quoteSummaryData.sumAssured) | formatNum }}</h2>
                            </div>
                            <div class="tw-w-full md:tw-w-1/2 tw-mt-4 md:tw-mt-0">
                                <h3 class="c-blue-text tw-font-bold tw-text-sm">Premium Payable {{ quoteSummaryData.premium | frequencyTitles }}</h3>
                                <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">KES {{ Math.round(quoteSummaryData.premium) | formatNum }}</h2>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="tw-flex tw-flex-wrap tw-my-3 md:tw-my-5 tw-justify-center">
                        <div class="tw-space-x-2">
                            <v-btn outlined color="primary">Email Quote</v-btn>
                            <v-btn outlined color="primary">Download</v-btn>
                        </div>
                        </div>
                        <div class="tw-flex tw-justify-center tw-mt-3 md:tw-mt-5">
                        <v-btn disabled="true" color="warning">Proceed</v-btn>
                        </div>
                </div>
                </v-container>
            </v-card>
        </v-dialog>
</template>

<script>
import { formatToHuman } from '@/utils/time';
import helpers from '@/utils/helpers';

export default {
  name: 'ClientQuoteSummary',
  props: {
    quoteSummaryData: {
      type: Object,
      required: true,
    },
    dialogView: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
  methods: {
    exitDialog() {
      this.$emit('changeDialog');
    },
  },

  filters: {
    formatToHuman,
    formatNum(num) {
      return helpers.numberToString(num);
    },
    frequencyTitles(title) {
      const lib = {
        Q: 'Quarterly',
        M: 'Monthly',
        Y: 'Yearly',
        S: 'Semi Annually',
        Sp: 'Single Premium',
        A: 'Annually',
      };
      return lib[title];
    },
  },

};
</script>
